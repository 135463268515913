<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else-if="Object.keys(studentInfos).length" class="my-3">
      <h4 class="text-center my-3">Оценки иностранных дисциплин</h4>

      <div class="card my-3">
        <div class="card-body">
          <div class="card-title mb-4">
            <h5>Информация о студенте</h5>
          </div>
          <div>
            <p>
              <b>ФИО:</b> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }} {{studentInfos?.middle_name }}
            </p>
            <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
            <p>
              <b>ОП:</b> {{studentInfos?.educationProgram?.education_speciality_code}}
              {{studentInfos?.educationProgram?.education_speciality_name}}
            </p>
            <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
            <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
            <p><b>Курс:</b> {{ studentInfos?.study_course }}</p>
            <p class="mb-0"><b>Группа:</b> {{ studentInfos?.studentGroups?.name }}</p>
          </div>
        </div>
      </div>

      <div v-if="ratings.length">
        <DataTable :value="ratings" showGridlines stripedRows>
          <Column field="discipline_name" header="Дисциплина"></Column>
          <Column field="semester" header="Семестр"></Column>
          <Column field="credit" header="Кредит"></Column>
          <Column field="total" header="Общий балл">
            <template #body="{data}">
              <input class="form-control" type="number" max="100" min="0" placeholder="Общий балл"
                     v-model="data.total">
            </template>
          </Column>
        </DataTable>

        <div class="text-center mt-3 mb-4">
          <button type="button" class="btn btn-primary" @click="putTotalGrades" :disabled="btnSaveGradesStatus">
            <span v-if="btnSaveGradesStatus" class="spinner-grow spinner-grow-sm" role="status"
                  aria-hidden="true"></span>
            {{ btnSaveGradesStatus ? 'Сохранение' : 'Сохранить' }}
          </button>
        </div>
      </div>
      <div v-else>
        <h5 class="text-center mt-4">
          Здесь пусто
        </h5>
      </div>

    </div>
    <div v-else>
      <h5 class="text-center mt-4">
        Здесь пусто
      </h5>
    </div>


  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'

  export default {
    name: "GradesOfForeignDisciplines",
    data() {
      return {
        loading: true,
        studentId: +this.$route.params.id || 0,
        studentInfos: {},
        ratings: [],
        btnSaveGradesStatus: false,
      }
    },
    methods: {
      ...mapActions('foreignDiscipline', ['GET_STUDENT_INFO', 'GET_GRADES_OF_FOREIGN_DISCIPLINES', 'PUT_GRADES_OF_FOREIGN_DISCIPLINES']),
      async getStudentInfo(studentId) {
        this.studentInfos = await this.GET_STUDENT_INFO(studentId)
      },
      async getRatings(studentId) {
        this.ratings = await this.GET_GRADES_OF_FOREIGN_DISCIPLINES(studentId)
      },
      async putTotalGrades() {
        this.btnSaveGradesStatus = true
        const ratings = this.ratings.map(i => ({student_ratings_id: i.id, total: i.total}))

        const {success} = await this.PUT_GRADES_OF_FOREIGN_DISCIPLINES(ratings)
        if (success) {
          this.$message({text: `Оценки успешно поставлены`})
        }
        this.btnSaveGradesStatus = false
      },
    },
    async mounted() {
      if (this.studentId) {
        await this.getStudentInfo(this.studentId)
        await this.getRatings(this.studentId)
      }
      this.loading = false
    }
  }
</script>

<style scoped>

</style>